<template>
	<article
		:class="{
			mobile: mobileMode,
			desktop: !mobileMode,
		}"
		:style="{
			// width: !mobileMode && width,
		}"
		class="column"

	>
		<header v-if="content.title || !content.start_from_top" class="header">
			<h2
				v-if="title"
				class="title"
				v-html="title"
			/>
		</header>
		<section v-if="content.body" class="body">
			<template
				v-for="(item, index) in content.body"
			>
				<!-- tag-list -->
				<tag-list
					v-if="item.type === 'category'"
					:key="`${index}-tag-list`"
					:tags="getTagsByGroup(item.value.category)"
					:clickable="item.value.is_links"
					class="column-body__tag-list column-body__item"
				/>

				<!-- image -->
				<div
					v-else-if="item.type === 'image'"
					:key="`${index}-image`"
					class="column-body__image column-body__item"
				>
					<a
						v-if="item.value.url"
						:href="item.value.url"
						class="image__a"
						target="_blank"
					>
						<img
							:src="item.value.image.file"
							:alt="item.value.image.title"
							class="image__img loading"
							@load="imageLoadHandler"
						>
					</a>
					<img
						v-else
						:src="item.value.image.file"
						:alt="item.value.image.title"
						class="image__img loading"
						@load="imageLoadHandler"
					>
				</div>
				<section
					v-else-if="item.type === 'text'"
					:key="`${index}-text`"
					class="column-body__text column-body__item"
					v-html="item.value"
					ref="textSection"
				/>
			</template>
		</section>
	</article>
</template>


<script>
import { cleanOfBlockTags } from '@/utilites.js';
import TagList from '@/components/tag-list.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
// import { REF_ROOT_FONT_SIZE } from '@/constants/mesures.js';

// https://en.wikipedia.org/wiki/Zero-width_space
const createZWSPTextNode = () => document.createTextNode(String.fromCharCode(8203));

export default {
	name: 'Column',
	data() {
		return {

		};
	},


	components: {
		TagList
	},


	props: {
		content: {
			type: Object,
			required: true,
			validator(value) {
				return value.title || value.body;
			},
		},
	},


	computed: {
		...mapState('tags', ['tags']),
		...mapGetters('tags', ['getTagsByGroup']),
		...mapGetters('browser', ['mobileMode']),

		title() {
			return cleanOfBlockTags(this.content.title);
		}

	},


	methods: {
		...mapActions('tags', ['getTags']),

		imageLoadHandler(evt) {
			const img = evt.target;
			img.style.width = `${img.naturalWidth / 2}px`;
			img.style.height = `${img.naturalHeight / 2}px`;
			img.classList.remove('loading');
		},

		/**
		 * Applies inline list style to every <ul> of text blocks
		 */
		applyListStyle() {
			const uls = this.$refs.textSection.reduce((uls, element) => uls.concat([...element.querySelectorAll('ul')]), []);
			uls.forEach(ul => {
				if (ul.classList.contains('inlilne-list')) {
					return;
				}
				ul.classList.add('inlilne-list');
				[...ul.children].forEach((li, index) => {
					if (index) {
						// insert textNode `&zwsp;` between <li>s
						ul.insertBefore(createZWSPTextNode(), li);
					}

					const wrapper = document.createElement('span');
					li.classList.add('inlilne-list__item')
					wrapper.append(...li.childNodes);
					wrapper.classList.add('inlilne-list__item-wrapper');
					li.append(wrapper);
				})
			});
		},
	},



	watch: {
		content: {
			deep: true,
			handler(current, previous) {
				this.$nextTick(() => {
					this.applyListStyle();
				});
			}
		}
	},

	created() {
		if (this.tags.length === 0) {
			this.getTags();
		}
	},

	mounted() {
		this.$nextTick(() => {
			this.applyListStyle();
		});
	}
}
</script>

<style lang="sass" scoped>
.column
	position: relative
	overflow: hidden

.header
	.mobile &
		@extend %page-title-mobile !optional

	.desktop &
		@extend %page-title-desktop


.title
	margin: 0

	.mobile &
		@extend %h2-mobile
		margin-bottom: 30px

	.desktop &
		@extend %h2-desktop
		margin-bottom: 34px


.body
	.mobile &
		@extend %p-mobile

	.desktop &
		@extend %p-desktop


.column-body__item
	.mobile &
		&:not(:last-child)
			margin-bottom: rslv($page__item-mgn-y__mob)

		&:not(:first-child)
			margin-top: rslv($page__item-mgn-y__mob)

	.desktop &
		&:not(:last-child)
			margin-bottom: rslv($page__item-mgn-y)

		&:not(:first-child)
			margin-top: rslv($page__item-mgn-y)


.column-body__text
	& ::v-deep p
		// text-indent: 2em
		margin: 0
		padding: 0
		// white-space: pre-wrap // preserves all \s

		.mobile &
			@extend %p-mobile
			&:not(:last-child)
				margin-bottom: rslv($fs__default-text__mob * $lh__default-text__mob)

		.desktop &
			@extend %p-desktop
			&:not(:last-child)
				margin-bottom: rslv($fs__default-text * $lh__default-text)

	& ::v-deep nobr
		white-space: nowrap

	& ::v-deep .margined_links a
		margin-right: 1.5em


.column-body__tag-list
	display: block


.column-body__image
	.image__a
		display: block

	.image__img
		display: block
		max-width: 100%
		height: auto
		transition: opacity .2s ease
		object-fit: scale-down

		&.loading
			opacity: 0

::v-deep .inlilne-list
	display: inline
	margin: 0
	padding: 0
	list-style: none
	overflow: hidden

	.inlilne-list__item
		display: inline
		margin-right: 1.2em
		margin-left: -1.2em
		white-space: nowrap
		&::before
			content: ' / '

	.inlilne-list__item-wrapper
		white-space: normal
</style>
