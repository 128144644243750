var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"std-page",class:{
		'mobile': _vm.mobileMode,
		'desktop': !_vm.mobileMode,
		'standalone': _vm.standalone,
	},attrs:{"id":_vm.slug}},[_c('header',{staticClass:"page-header"},[_c('h1',{staticClass:"page-title",domProps:{"innerHTML":_vm._s(_vm.title)}})]),_c('div',{staticClass:"columns-wrapper",style:({
			'grid-template-columns': new Array(_vm.columnsCount).fill('1fr').join(' '),
			'--columns': _vm.columnsCount,
		})},[_vm._l((_vm.columns),function(columnContent,index){return [_c('column',{key:(index + "-column"),staticClass:"column",style:({
					'--cell-width': _vm.getColumnWidth(columnContent),
				}),attrs:{"content":columnContent}}),(columnContent.separator)?_c('span',{key:(index + "-separator"),staticClass:"separator"}):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }