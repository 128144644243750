<template>
	<section
		:id="slug"
		:class="{
			'mobile': mobileMode,
			'desktop': !mobileMode,
			'standalone': standalone,
		}"
		class="std-page"
	>
		<header class="page-header">
			<h1 class="page-title" v-html="title" />
		</header>

		<div
			:style="{
				'grid-template-columns': new Array(columnsCount).fill('1fr').join(' '),
				'--columns': columnsCount,
			}"
			class="columns-wrapper"
		>
			<template
				v-for="(columnContent, index) in columns"
			>
				<column
					:key="`${index}-column`"
					:content="columnContent"
					:style="{
						'--cell-width': getColumnWidth(columnContent),
					}"
					class="column"
				/>
				<span v-if="columnContent.separator" :key="`${index}-separator`" class="separator" />
			</template>
		</div>
	</section>
</template>

<script>
import { mapState } from 'vuex';
import subpageMixin from './subpage-mixin';
import Column from '@/components/column.vue';

export default {
	mixins: [
		// adds `pageData` prop and `page` computed value
		subpageMixin,
	],


	name: 'StandardPage',


	components: {
		Column,
	},


	props: {
		// `pageData` via subpage mixin
	},


	computed: {
		// [page, slug] via subpage mixin

		...mapState('browser', { windowWidth: 'width' }),

		columnsCount() {
			// based on viewport width
			// let pagePadding = this.mobileMode ? 25 : 40;
			// let gridGap = 60;
			// let reference_col_width = 400;
			// return Math.floor((this.windowWidth - 2 * pagePadding + gridGap) / (reference_col_width + gridGap));

			return this.mobileMode ? 1 : 3;
		},


		columns() {
			return this.page.standard_block;
		},
	},

	methods: {
		getColumnWidth(columnData) {
			if (!columnData || !columnData.size || columnData.size < 1) return 1;
			if (columnData.size > this.columnsCount) return this.columnsCount;
			return columnData.size;
		},
	},
};
</script>

<style lang="sass" scoped>
.std-page
	@extend %page !optional

	&.mobile
		@extend %page-mobile

		&.standalone
			@extend %page-mobile-standalone

	&.desktop
		@extend %page-desktop

		&.standalone
			@extend %page-desktop-standalone

.page-header
	@extend %page-header !optional

	.mobile &
		@extend %page-header-mobile

	.mobile.standalone &
		@extend %page-header-mobile-standalone

	.desktop &
		@extend %page-header-desktop

	.desktop.standalone &
		@extend %page-header-desktop-standalone

.page-title
	@extend %page-title

	.mobile &
		@extend %page-title-mobile

	.desktop &
		@extend %page-title-desktop

.columns-wrapper
	--columns: 3
	display: grid
	grid-template-columns: repeat(var(--columns, 3), 1fr)

	// grid-column-gap: rslv($pad__x)
	.desktop &
		grid-column-gap: rslv($pad__x * 2)
		grid-row-gap: rslv($pad__x)

	.mobile &
		grid-column-gap: rslv($pad__x__mob * 2)
		grid-row-gap: rslv($pad__x__mob)

	.separator
		display: block
		grid-column-end: span var(--columns, 3)
		align-self: center
		border-bottom: 1px solid #dddddd

	& > *
		--cell-width: 1
		grid-column-end: span var(--cell-width, 1)


		// .desktop &
		// 	// max-width: calc(100vw - #{rslv($pad__x)} * 2)

		// 	// &:not(:last-child)
		// 	// 	margin: 0 rslv($p-std__column-gap)
		// 	// 	margin-left: 0

		// .mobile &
		// 	// max-width: calc(100vw - #{rslv($pad__x__mob)} * 2)

		// 	// &:not(:last-child)
		// 	// 	margin-bottom: rslv($p-std__column-gap__mob)
</style>
